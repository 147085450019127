import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import {Link, useNavigate} from 'react-router-dom';
// import { Link } from 'react-router-dom'

const Login = () => {
  const [message, setMessage] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignup = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    try {
        const response = await axios.post(
          "https://boup.dev-rr.xyz/api/login",
          formData
        );
        const accessToken = response?.data?.accessToken;
        localStorage.setItem("Token", accessToken);
        const user = response?.data?.user?.role?.name;
        localStorage.setItem("Role_name", user);
        console.log(response, "Login Successful");
        setTimeout(() => {
            toast.success(response?.data?.message); 
            setMessage(response?.data?.message); 
        }, 1000);
        navigate("/");
        setEmail("");
        setPassword("");
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };

  return (
    <>
    <div className="container" style={{ marginTop: "40px" }}>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row">
        <div className="text-center">{message}</div>
        <div className="col-lg-6 col-md-8 mx-auto my-4">
          <div
              className='py-4 px-4 sign-up rounded'
              style={{ backgroundColor: '#34e3c982', border: '1px solid rgba(1, 1, 1, 0.3)' }}>
              <h3 className='text-center pb-3 fs-4 fw-bold'>Login</h3>
                  <input
                    className='w-100 form-control'
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <input
                    className='w-100 form-control'
                    placeholder='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                <button className='btn btn-primary w-50 my-3 fw-bold d-flex flex-column align-items-center mx-auto' onClick={handleSignup}>
                Login
              </button>
              <div className="w-50 my-3 fw-bold d-flex flex-column align-items-center mx-auto">
               <span className="css"> Don't have an account ?</span> <Link to="/signup"> Sign up </Link>
              </div>
            </div>
        </div>
      </div>
    </div>
    <style>
     {`
    input.w-100.form-control {
        padding: 15px;
        margin-bottom: 25px;
     }
    h3.text-center.pb-3.fs-4.fw-bold {
        color: white;
        font-size: 2.2rem!important;
    }
    span.css{
        color: white;
    }
       `}
     </style>
    </>
  );
};

export default Login;
