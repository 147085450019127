import React from "react";

function About() {
  return (
    <>
      <section id="about">
        <div className="container pt-7 pb-15">
          <div className="card">
            <div className="card-body">
              <div className="row gx-md-8 gx-xl-10 gy-8">
                <div className="col-lg-5">
                  <figure className="rounded">
                    <img
                      className="img-fluid"
                      src="assets/img/photos/about.jpg"
                      srcset="./assets/img/photos/about@2x.jpg 2x"
                      alt=""
                    />
                  </figure>
                </div>
                {/* <!-- /column --> */}
                <div className="col-lg-7">
                  <h3 className="mb-3">
                    Life is an adventure. Select Creative Media Licenses and Subscriptions
                  </h3>
                  <p className="lead mb-7">
                    This offer cannot be combined with other promotions or
                    bundled with other offers. The offer is valid until
                    11/20/2023 and is applicable only to Image Standard License,
                    Footage Standard License, Music Standard License, Sound
                    Effects Standard License, Creative Flow+, PremiumBeat single
                    Music and SFX products, as well as Elements subscription and
                    pack products, including FLEX subscriptions. It does not
                    apply to purchases of Enhanced, Editorial, Enterprise,
                    Studios, PremiumBeat subscriptions, Essentials Plans, or
                    Team Plans. The discount will be applied only to the initial
                    payment for the purchase and not to any subsequent
                    installments or renewals of the same plan.
                  </p>
                  {/* <div className="row">
                    <div className="col-lg-6">
                      <h3 className="h4 mb-3">A Little About Me</h3>
                      <p>
                        <span className="dropcap">N</span> ulla vitae elit
                        libero, a pharetra augue. Dapuis mattis consectetur
                        purus sit amet fermentum. Fusce dapibus, tellus ac
                        cursus commodo, tortor mauris condimentum nibh, ut
                        fermentum massa justo sit amet risus. Sed posuere
                        consectetur est at lobortis. Fusce dapibus, tellus ac
                        cursus.
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="h4 mb-3">My Equipment</h3>
                      <ul className="unordered-list bullet-primary">
                        <li>Canon Eos 5D Mark IV 24-105mm</li>
                        <li>Manfrotto Compact Tripod</li>
                        <li>DJI Ronin MX 3-Axis Gimbal Stabilizer</li>
                        <li>Wondlan Wer01 Slider Time Lapse</li>
                        <li>Canon EF100-400MM Lens</li>
                        <li>Nikon D5 24-70mm F2.8</li>
                        <li>Nikon Af-S 24Mm F/1.4G Ed Lens</li>
                        <li>Wondlan Sniper Sn 2.1 Wf Wireless Dslr</li>
                      </ul>
                    </div>
                  </div> */}
                </div>
                {/* <!-- /column --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!--/.card-body --> */}
          </div>
          {/* <!--/.card --> */}
        </div>
      </section>
    </>
  );
}

export default About;
