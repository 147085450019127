import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function Portfolio() {
  const [selectedCategory] = useState("all");
  const [filteredImages, setFilteredImages] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [images, setImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  // const { id } = useParams();

  const fetchCategoriesData = async () => {
    try {
      const response = await axios.get("https://boup.dev-rr.xyz/api/category", {
        headers: {
          Accept: "application/json",
        },
      });

      console.log(response, "response of get");
      const categories = response?.data?.categories;
      console.log(categories, "allcategories are view");

      setCategories(categories);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchCategoriesImages = async (query) => {
    try {
      const response = await axios.get(
        `https://boup.dev-rr.xyz/api/image?category_id=${
          query === null ? "" : query
        }`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      console.log(response.data, "mil gyeee images");

      const images = response?.data?.images || [];
      setFilteredImages(images);
    } catch (error) {
      console.error("Error fetching images:", error);
      toast.error(error?.response?.data?.message || "Failed to fetch images");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCategoriesData();
    fetchCategoriesImages("");
  }, []);

  useEffect(() => {
    fetchCategoriesData();
    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get("search");
    if (searchParam) {
      setSearchQuery(searchParam);
    }
  }, []);

  useEffect(() => {
    const filteredCategories = categories.filter((category) =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setCategories(searchQuery ? filteredCategories : categories);
  }, [searchQuery, categories]);

  const categorySliderSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 1,
  };

  return (
    <>
      <section id="portfolio">
        <div className="container pt-7 pb-15">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-7 col-md-7 mx-auto">
                  <h2 className="mb-3 text-center pb-4">My Portfolio</h2>
                  <div className="text-center px-xxl-12">
                    <form action="#" className="wdg-search-form">
                      <input
                        type="text"
                        placeholder="Search for categories..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
                <div className="col-md-12 mb-4 mt-4 pt-5 pb-5">
                  <Slider {...categorySliderSettings}>
                    <div>
                      <Link
                        className={`filter-item ${
                          selectedCategory === "all" ? "active" : ""
                        }`}
                        onClick={() => fetchCategoriesImages("")}
                      >
                        All
                      </Link>
                    </div>
                    {categories.map((category) => (
                      <div key={category.id}>
                        <Link
                          className={`filter-item ${
                            selectedCategory === category.slug ? "active" : ""
                          }`}
                          onClick={() => fetchCategoriesImages(category.id)}
                        >
                          {category.name}
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <div className="row gx-md-6 gy-6 isotope">
                {filteredImages.map((image, index) => (
                  <div
                    key={index}
                    className={`item col-md-6 col-xl-4 ${selectedCategory} ${image.name}`}
                  >
                    <figure className="overlay rounded">
                      <a
                        href={image.watermarked_image}
                        data-glightbox={`title: Image ${index}`}
                        data-gallery="g-portfolio"
                      >
                        <img
                          src={`https://boup.dev-rr.xyz/storage/${image.watermarked_image}`}
                          srcSet={`${image.watermarked_image} 2x`}
                          alt=""
                        />
                      </a>
                      <figcaption>
                        <i className="si-photo_aperture"></i>
                      </figcaption>
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <style>
        {`
          .wdg-search-form {
            max-width: 300px;
            margin: 0 auto;
          }
          .wdg-search-form input {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 5px;
            box-sizing: border-box;
            outline: none;
            transition: border-color 0.3s;
          }
          .wdg-search-form input:focus {
            border-color: #007bff; /* Change to your preferred focus color */
          }
          `}
      </style>
    </>
  );
}

export default Portfolio;
