import React from "react";

function Services() {
  return (
    <>
       <section id="services">
      <div className="container pt-7 pb-15">
        <div className="card">
          <div className="card-body">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6 position-relative order-lg-2">
                <div className="overlap-grid overlap-grid-2">
                  <div className="item">
                    <figure className="rounded shadow"><img src="assets/img/photos/s1.jpg" srcset="./assets/img/photos/s1@2x.jpg 2x" alt="" /></figure>
                  </div>
                  <div className="item">
                    <figure className="rounded shadow"><img src="assets/img/photos/s2.jpg" srcset="./assets/img/photos/s2@2x.jpg 2x" alt="" /></figure>
                  </div>
                </div>
              </div>
              {/* <!--/column --> */}
              <div className="col-lg-6">
                <h2 className="mb-3">My Services</h2>
                <p className="lead mb-7">We’re committed to making creative decisions based on data-driven insights, experimenting, taking big swings and building cutting-edge products that surprise and delight our customers.</p>
                <div className="d-flex flex-row mb-5">
                  <div>
                    <div className="icon fs-44 text-white mt-1 me-5"><i className="si-photo_camera"></i></div>
                  </div>
                  <div>
                    <h3 className="h4 mb-1">Culture</h3>
                    <p className="mb-0">Innovation is in our DNA. Together we question, learn, collaborate, and achieve.</p>
                  </div>
                </div>
                <div className="d-flex flex-row mb-5">
                  <div>
                    <div className="icon fs-44 text-white mt-1 me-5"><i className="si-photo_magic-tools"></i></div>
                  </div>
                  <div>
                    <h3 className="h4 mb-1">Values</h3>
                    <p className="mb-0">Our values guide us in everything we do - from how we engage with each other, to how we make decisions for our company.</p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon fs-44 text-white mt-1 me-5"><i className="si-seo_video-marketing"></i></div>
                  </div>
                  <div>
                    <h3 className="h4 mb-1">Leadership</h3>
                    <p className="mb-0">Meet the executive team who drive Boup Images forward.</p>
                  </div>
                </div>
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/.row --> */}
          </div>
          {/* <!--/.card-body --> */}
        </div>
        {/* <!--/.card --> */}
      </div>
    </section>
    </>
  );
}

export default Services;
