// import React, { useState } from 'react';
// import { useEffect } from 'react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
// import {useNavigate} from 'react-router-dom';


const Signup = ({ datBtn }) => {
  const [message, setMessage] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const statusurl = searchParams.get("status");
  const messageurl = searchParams.get("message");


  // Ab 'status' aur 'message' values ko extract karen

  // Ab aap 'status' aur 'message' variables mein data ko use kar sakte hain
  console.log("Status:", statusurl);
  console.log("Message:", messageurl);
  const [email, setEmail] = useState('');
//   const [cellNo, setCellNo] = useState('');
  const [password, setPassword] = useState('');
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [rePassword, setRePassword] = useState('');

    const handleSignup = async () => {
    if (password === rePassword) {
    } else {
        setMessage("Password and Confirm Password do not match.");
    }
    const formData = new FormData();
    formData.append('first_name', fName)
    formData.append('last_name', lName)
    formData.append('email', email)
    // formData.append('phone', cellNo)
    formData.append('password', password)
    formData.append('password_confirmation', rePassword)
    try {
      const response = await axios.post('https://boup.dev-rr.xyz/api/register', formData);
        console.log(response, 'Sign Up Successful');
        setTimeout(() => {
            toast.success(response?.data?.message); 
            setMessage(response?.data?.message); 
        }, 1000);
        navigate("/login");
        setFName("")
        setLName("")
        setEmail("")
        setPassword("")
        setRePassword("")

    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <>

<div className="container" style={{ marginTop: "0px" }}>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row">
        <div className="text-center">{message}</div>
        <div className="col-lg-6 col-md-8 mx-auto my-3">
          <div
              className='py-4 px-4 sign-up rounded'
              style={{ backgroundColor: '#34e3c982', border: '1px solid rgba(1, 1, 1, 0.3)' }}>
              <div className='text-center fs-4 fw-bold' style={{color:"white"}}>Signup</div>
                  <div className='text-center' style={{color:"black"}}>Enter your personal details below</div>
              <div className="row p-2">
              <div className="col-md-6 mx-auto my-2 mt-3">
              <input
                className='w-45 form-control'
                type='text'
                placeholder='First Name'
                value={fName}
                onChange={(e) => setFName(e.target.value)}
              />
              </div>
              <div className="col-md-6 mx-auto my-2 mt-3">
              <input
                className='w-45 form-control'
                type='text'
                placeholder='Last Name'
                value={lName}
                onChange={(e) => setLName(e.target.value)}
              />
            </div>
              </div>
              <div className="row p-2">
              <div className="col-md-12 mx-auto my-2">
              <input
                className='w-100 form-control'
                placeholder='Email'
                value={email}
                type='email'
                onChange={(e) => setEmail(e.target.value)}
              />
              </div>
              </div>
              {/* <div className="row">
              <div className="col-md-12 mx-auto my-2">
              <input
                className='w-100 form-control'
                placeholder='Cell No'
                value={cellNo}
                type='number'
                onChange={(e) => setCellNo(e.target.value)}
              />
            </div>
            </div> */}
            <div className="row p-2">
              <div className="col-md-6 mx-auto my-2">
              <input
                className='w-100 form-control'
                placeholder='Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
                </div>
                <div className="col-md-6 mx-auto my-2">
                <input
                className='w-100 form-control'
                placeholder='Confirm-Password'
                type='password'
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
              />
                </div>
            </div>
              <button className='btn btn-primary w-100 my-2 fw-bold' onClick={handleSignup}>
                Resgister
              </button>
            </div>
          </div>
        </div>
      </div>
      <style>
        {
            `
            .fs-4 {
                font-size: 2.2rem!important;
            }
            `
        }
      </style>
    </>
  );
};

export default Signup;