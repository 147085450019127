import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import useHistory from "use-history";

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const accessToken = localStorage.getItem("Token");
    if (accessToken) {
      setIsLoggedIn(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <>
     <header className={`header ${isSticky ? "sticky" : ""}`}>
      <nav className="navbar navbar-expand-lg classic transparent p-5">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <Link to="/">
              <img src="assets/img/Boupimg.png" srcset="./assets/img/Boupimgpng 2x" alt="" width={130} height={40} />
            </Link>
          </div>
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            <div className="offcanvas-header d-lg-none">
              <Link to="/"><img src="assets/img/Boupimg.png" srcset="./assets/img/Boupimg.png 2x" alt="" width={130} height={40} /></Link>
              <button type="button" className="btn btn-circle btn-white btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body d-flex flex-column h-100">
              <ul className="navbar-nav">
              
              </ul>
              <div className="d-lg-none mt-auto pt-6 pb-6 order-4">
                <Link to="https://demos.elemisthemes.com/cdn-cgi/l/email-protection#f8db" className="link-body"><span className="__cf_email__" data-cfemail="88e1e6eee7c8e4e7ebfdfba6ebe7e5">[email&#160;protected]</span></Link>
                <br /> 00 (123) 456 78 90 <br />
                <nav className="nav social justify-content-end text-end">
                {isLoggedIn ? (
                  <li className="nav-item">
                    <Link className="nav-link scroll" to="#" onClick={handleLogout}>
                      <span>&#x1F464;</span> Logout
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link scroll" to="/login">
                      <span>&#x1F464;</span> Login
                    </Link>
                  </li>
                )}
              </nav>
              </div>
            </div>
          </div>
          <div className="navbar-other w-100 d-flex ms-auto">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item">
              <nav className="nav social justify-content-end text-end">
                {isLoggedIn ? (
                  <li className="nav-item">
                    <Link className="nav-link scroll" to="#" onClick={handleLogout}>
                      <span>&#x1F464;</span> Logout
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link scroll" to="/login">
                      <span>&#x1F464;</span> Login
                    </Link>
                  </li>
                )}
              </nav>
              </li>
              <li className="nav-item d-lg-none">
                <button className="hamburger offcanvas-nav-btn"><span></span></button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
      <style>
        {`
            .header {
              background-color: transparent; /* Set your header background color */
              transition: all 0.3s;
            }
  
            .sticky {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              background-color: rgba(10,11,15,.9);
              z-index: 1000;
              /* Add other styles for the sticky header */
            }  
        `}
      </style>
    </>
  );
}
export default Header;
