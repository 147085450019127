// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import HeroSec from './pages/index/heroSec';
// import Footer from './layout/footer';
// import SmoothScroll from './ScrollTop';
// import { useRef } from 'react';
import Header from './layout/header';
// import Index from './pages';
// import About from './pages/index/about';
// import Portfolio from './pages/index/portfolio';
// import Gallery from './pages/index/gallery';
// import Services from './pages/index/services';
// import Contact from './pages/index/contact';
import Index from './pages';
import Login from './pages/auth/login';
import Signup from './pages/auth/signup';

function App() {
  // const top = useRef();
  return (
    <>
    {/* <div ref={top} /> */}
    <BrowserRouter>
    {/* <SmoothScroll top={top} /> */}
    <Header />
    <Routes>
    <Route path='/login' element={<Login />}/>
    <Route path='/signup' element={<Signup />}/>
    <Route path='/' element={<Index />}/>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
