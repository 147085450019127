import React from "react";
import { Link } from "react-router-dom";

function Gallery() {
  return (
    <>
        <section id="gallery">
      <div className="container pt-7 pb-15">
        <div className="card">
          <div className="card-body">
            <h2 className="mb-3 text-center">My Gallery</h2>
            <p className="lead mb-10 text-center px-xl-12">Explore images that ignite your creativity
              Here are some of the best pictures around the world.</p>
            <div className="grid grid-view">
              <div className="row gx-md-6 gy-6 isotope">
                <div className="item col-md-6 col-xl-4">
                  <figure className="overlay rounded">
                    <Link  href="assets/img/photos/an1.jpg" data-glightbox="title: Antarctica" data-gallery="g-antarctica">
                      <img src="assets/img/photos/an-th.jpg" srcset="./assets/img/photos/an-th@2x.jpg 2x" alt="" />
                    </Link>  
                    <figcaption>
                      <h4 className="from-top h6 mb-0">Antarctica</h4>
                    </figcaption>
                  </figure>
                  <ul className="d-none">
                    <li><Link  href="assets/img/photos/an2.jpg" data-glightbox="title: Antarctica" data-gallery="g-antarctica"></Link>  </li>
                    <li><Link  href="assets/img/photos/an3.jpg" data-glightbox="title: Antarctica" data-gallery="g-antarctica"></Link>  </li>
                    <li><Link  href="assets/img/photos/an4.jpg" data-glightbox="title: Antarctica" data-gallery="g-antarctica"></Link>  </li>
                    <li><Link  href="assets/img/photos/an5.jpg" data-glightbox="title: Antarctica" data-gallery="g-antarctica"></Link>  </li>
                    <li><Link  href="assets/img/photos/an6.jpg" data-glightbox="title: Antarctica" data-gallery="g-antarctica"></Link>  </li>
                  </ul>
                </div>
                {/* <!-- /.item --> */}
                <div className="item col-md-6 col-xl-4">
                  <figure className="overlay rounded">
                    <Link  href="assets/img/photos/au1.jpg" data-glightbox="title: Australia" data-gallery="g-australia">
                      <img src="assets/img/photos/au-th.jpg" srcset="./assets/img/photos/au-th@2x.jpg 2x" alt="" />
                    </Link>  
                    <figcaption>
                      <h4 className="from-top h6 mb-0">Australia</h4>
                    </figcaption>
                  </figure>
                  <ul className="d-none">
                    <li><Link  href="assets/img/photos/au2.jpg" data-glightbox="title: Australia" data-gallery="g-australia"></Link>  </li>
                    <li><Link  href="assets/img/photos/au3.jpg" data-glightbox="title: Australia" data-gallery="g-australia"></Link>  </li>
                    <li><Link  href="assets/img/photos/au4.jpg" data-glightbox="title: Australia" data-gallery="g-australia"></Link>  </li>
                    <li><Link  href="assets/img/photos/au5.jpg" data-glightbox="title: Australia" data-gallery="g-australia"></Link>  </li>
                    <li><Link  href="assets/img/photos/au6.jpg" data-glightbox="title: Australia" data-gallery="g-australia"></Link>  </li>
                  </ul>
                </div>
                {/* <!-- /.item --> */}
                <div className="item col-md-6 col-xl-4">
                  <figure className="overlay rounded">
                    <Link  href="assets/img/photos/eg1.jpg" data-glightbox="title: Egypt" data-gallery="g-egypt">
                      <img src="assets/img/photos/eg-th.jpg" srcset="./assets/img/photos/eg-th@2x.jpg 2x" alt="" />
                    </Link>  
                    <figcaption>
                      <h4 className="from-top h6 mb-0">Egypt</h4>
                    </figcaption>
                  </figure>
                  <ul className="d-none">
                    <li><Link  href="assets/img/photos/eg2.jpg" data-glightbox="title: Egypt" data-gallery="g-egypt"></Link>  </li>
                    <li><Link  href="assets/img/photos/eg3.jpg" data-glightbox="title: Egypt" data-gallery="g-egypt"></Link>  </li>
                    <li><Link  href="assets/img/photos/eg4.jpg" data-glightbox="title: Egypt" data-gallery="g-egypt"></Link>  </li>
                    <li><Link  href="assets/img/photos/eg5.jpg" data-glightbox="title: Egypt" data-gallery="g-egypt"></Link>  </li>
                    <li><Link  href="assets/img/photos/eg6.jpg" data-glightbox="title: Egypt" data-gallery="g-egypt"></Link>  </li>
                  </ul>
                </div>
                {/* <!-- /.item --> */}
                <div className="item col-md-6 col-xl-4">
                  <figure className="overlay rounded">
                    <Link  href="assets/img/photos/sc1.jpg" data-glightbox="title: Scandinavia" data-gallery="g-scandinavia">
                      <img src="assets/img/photos/sc-th.jpg" srcset="./assets/img/photos/sc-th@2x.jpg 2x" alt="" />
                    </Link>  
                    <figcaption>
                      <h4 className="from-top h6 mb-0">Scandinavia</h4>
                    </figcaption>
                  </figure>
                  <ul className="d-none">
                    <li><Link  href="assets/img/photos/sc2.jpg" data-glightbox="title: Scandinavia" data-gallery="g-scandinavia"></Link>  </li>
                    <li><Link  href="assets/img/photos/sc3.jpg" data-glightbox="title: Scandinavia" data-gallery="g-scandinavia"></Link>  </li>
                    <li><Link  href="assets/img/photos/sc4.jpg" data-glightbox="title: Scandinavia" data-gallery="g-scandinavia"></Link>  </li>
                    <li><Link  href="assets/img/photos/sc5.jpg" data-glightbox="title: Scandinavia" data-gallery="g-scandinavia"></Link>  </li>
                    <li><Link  href="assets/img/photos/sc6.jpg" data-glightbox="title: Scandinavia" data-gallery="g-scandinavia"></Link>  </li>
                  </ul>
                </div>
                {/* <!-- /.item --> */}
                <div className="item col-md-6 col-xl-4">
                  <figure className="overlay rounded">
                    <Link  href="assets/img/photos/aa1.jpg" data-glightbox="title: Anatolia" data-gallery="g-anatolia">
                      <img src="assets/img/photos/aa-th.jpg" srcset="./assets/img/photos/aa-th@2x.jpg 2x" alt="" />
                    </Link>  
                    <figcaption>
                      <h4 className="from-top h6 mb-0">Anatolia</h4>
                    </figcaption>
                  </figure>
                  <ul className="d-none">
                    <li><Link  href="assets/img/photos/aa2.jpg" data-glightbox="title: Anatolia" data-gallery="g-anatolia"></Link>  </li>
                    <li><Link  href="assets/img/photos/aa3.jpg" data-glightbox="title: Anatolia" data-gallery="g-anatolia"></Link>  </li>
                    <li><Link  href="assets/img/photos/aa4.jpg" data-glightbox="title: Anatolia" data-gallery="g-anatolia"></Link>  </li>
                    <li><Link  href="assets/img/photos/aa5.jpg" data-glightbox="title: Anatolia" data-gallery="g-anatolia"></Link>  </li>
                    <li><Link  href="assets/img/photos/aa6.jpg" data-glightbox="title: Anatolia" data-gallery="g-anatolia"></Link>  </li>
                  </ul>
                </div>
                {/* <!-- /.item --> */}
                <div className="item col-md-6 col-xl-4">
                  <figure className="overlay rounded">
                    <Link  href="assets/img/photos/br1.jpg" data-glightbox="title: Brazil" data-gallery="g-brazil">
                      <img src="assets/img/photos/br-th.jpg" srcset="./assets/img/photos/br-th@2x.jpg 2x" alt="" />
                    </Link>  
                    <figcaption>
                      <h4 className="from-top h6 mb-0">Brazil</h4>
                    </figcaption>
                  </figure>
                  <ul className="d-none">
                    <li><Link  href="assets/img/photos/br2.jpg" data-glightbox="title: Brazil" data-gallery="g-brazil"></Link>  </li>
                    <li><Link  href="assets/img/photos/br3.jpg" data-glightbox="title: Brazil" data-gallery="g-brazil"></Link>  </li>
                    <li><Link  href="assets/img/photos/br4.jpg" data-glightbox="title: Brazil" data-gallery="g-brazil"></Link>  </li>
                    <li><Link  href="assets/img/photos/br5.jpg" data-glightbox="title: Brazil" data-gallery="g-brazil"></Link>  </li>
                    <li><Link  href="assets/img/photos/br6.jpg" data-glightbox="title: Brazil" data-gallery="g-brazil"></Link>  </li>
                  </ul>
                </div>
                {/* <!-- /.item --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.grid --> */}
          </div>
          {/* <!--/.card-body --> */}
        </div>
        {/* <!--/.card --> */}
      </div>
    </section>
    </>
  );
}

export default Gallery;
