import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

function Hero() {
  return (
    <>
    <section id="home">
      <div className="container vh-100 d-flex flex-column align-items-center justify-content-center">
        <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
        <div className="text-center">
          <h1 className="display-1 mb-8">Strengthen your storytelling</h1>
          <h2 className="fs-16 ls-lg text-uppercase mb-5">
          You never have to compromise your creative vision when you use Shutterstock. 
          <br />If it’s in your head, it’s on our site.
          </h2>
          {/* <span>
            <a href="#portfolio" className="btn btn-lg btn-white scroll">
              View My Work
            </a>
          </span> */}
          </div>
        </ScrollAnimation>
      </div>
    </section>
    </>
  );
}

export default Hero;
