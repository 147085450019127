import React from "react";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
        <section id="contact">
      <div class="container pt-7 pb-15">
        <div class="card">
          <div class="card-body">
            <h2 class="mb-3">Get In Touch</h2>
            <p class="lead mb-10">Got any questions? Don't hesitate to get in touch.</p>
            <div class="row gy-10 gx-lg-8 gx-xl-12">
              <div class="col-lg-8">
                <form class="contact-form needs-validation" method="post" action="https://demos.elemisthemes.com/locus/assets/php/contact.php" novalidate>
                  <div class="messages"></div>
                  <div class="row gx-4">
                    <div class="col-md-6">
                      <div class="form-floating mb-6">
                        <input id="form_name" type="text" name="name" class="form-control" placeholder="Jane" required />
                        <label for="form_name">First Name *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback"> Please enter your first name. </div>
                      </div>
                    </div>
                    {/* <!-- /column --> */}
                    <div class="col-md-6">
                      <div class="form-floating mb-6">
                        <input id="form_lastname" type="text" name="surname" class="form-control" placeholder="Doe" required />
                        <label for="form_lastname">Last Name *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback"> Please enter your last name. </div>
                      </div>
                    </div>
                    {/* <!-- /column --> */}
                    <div class="col-md-6">
                      <div class="form-floating mb-6">
                        <input id="form_email" type="email" name="email" class="form-control" placeholder="jane.doe@example.com" required />
                        <label for="form_email">Email *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback"> Please provide a valid email address. </div>
                      </div>
                    </div>
                    {/* <!-- /column --> */}
                    <div class="col-md-6">
                      <div class="form-select-wrapper mb-6">
                        <select class="form-select" id="form-select" name="subject-select" required>
                          <option selected disabled value="">Select a subject</option>
                          <option value="Question">Question</option>
                          <option value="Pricing">Pricing</option>
                          <option value="Other">Other</option>
                        </select>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback"> Please select a department. </div>
                      </div>
                    </div>
                    {/* <!-- /column --> */}
                    <div class="col-12">
                      <div class="form-floating mb-6">
                        <textarea id="form_message" name="message" class="form-control" placeholder="Your message" style={{height: '150px'}} required></textarea>
                        <label for="form_message">Message *</label>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback"> Please enter your messsage. </div>
                      </div>
                    </div>
                    {/* <!-- /column --> */}
                    <div class="col-12">
                      <div class="form-check mb-6">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                        <label class="form-check-label" for="invalidCheck"> I agree to <Link to="#" class="hover">terms and policy</Link>. </label>
                        <div class="invalid-feedback"> You must agree before submitting. </div>
                      </div>
                    </div>
                    {/* <!-- /column --> */}
                    <div class="col-12">
                      <input type="submit" class="btn btn-white btn-send" value="Send message" />
                    </div>
                    {/* <!-- /column --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </form>
                {/* <!-- /form --> */}
              </div>
              {/* <!--/column --> */}
              <div class="col-lg-4">
                <div class="d-flex flex-row">
                  <div>
                    <div class="icon text-white fs-30 me-5 mt-1"> <i class="si-ecommerce_shop-location"></i> </div>
                  </div>
                  <div>
                    <h5 class="mb-1">Address</h5>
                    <address>Moonshine St. 14/05 Light City, London, United Kingdom</address>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div>
                    <div class="icon text-white fs-30 me-5 mt-1"> <i class="si-phone_drop-call"></i> </div>
                  </div>
                  <div>
                    <h5 class="mb-1">Phone</h5>
                    <p>00 (123) 456 78 90 <br />00 (987) 654 32 10</p>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div>
                    <div class="icon text-white fs-30 me-5 mt-1"> <i class="si-mail_send-arrow"></i> </div>
                  </div>
                  <div>
                    <h5 class="mb-1">E-mail</h5>
                    <p class="mb-0"><a href="https://demos.elemisthemes.com/cdn-cgi/l/email-protection#c1e2" class="link-body"><span class="__cf_email__" data-cfemail="264f484049664a494553550845494b">info@boupimages.com</span></a></p>
                  </div>
                </div>
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/.row --> */}
            <h2 class="mt-10 mb-8 text-center">Follow Me on Instagram</h2>
            <div class="grid grid-view position-relative mb-10">
              <div class="position-absolute mt-3" style={{top: '50%', left: '50%', zIndex: '2', transform: 'translate(-50%,-50%)'}}><Link to="#" class="btn btn-white"><i class="uil uil-instagram fs-18 me-1"></i> Follow Me</Link></div>
              <div class="row gx-md-6 gy-6 isotope opacity-50">
                <div class="item col-md-6 col-lg-3">
                  <figure class="rounded"><img src="assets/img/photos/i1.jpg" srcset="./assets/img/photos/i1@2x.jpg 2x" alt="" /></figure>
                </div>
                {/* <!-- /.item --> */}
                <div class="item col-md-6 col-lg-3">
                  <figure class="rounded"><img src="assets/img/photos/i2.jpg" srcset="./assets/img/photos/i2@2x.jpg 2x" alt="" /></figure>
                </div>
                {/* <!-- /.item --> */}
                <div class="item col-md-6 col-lg-3">
                  <figure class="rounded"><img src="assets/img/photos/i3.jpg" srcset="./assets/img/photos/i3@2x.jpg 2x" alt="" /></figure>
                </div>
                {/* <!-- /.item --> */}
                <div class="item col-md-6 col-lg-3">
                  <figure class="rounded"><img src="assets/img/photos/i4.jpg" srcset="./assets/img/photos/i4@2x.jpg 2x" alt="" /></figure>
                </div>
                {/* <!-- /.item --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.grid --> */}
            <p class="mb-0 text-center">© 2023 Boupimages. All rights reserved.</p>
          </div>
          {/* <!--/.card-body --> */}
        </div>
        {/* <!--/.card --> */}
      </div>
      {/* <!--/.container --> */}
    </section>
    </>
  );
}

export default Contact;
