// import React, { useEffect } from "react";
import Hero from "./index/hero";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import About from "./index/about";
import Portfolio from "./index/portfolio";
import Gallery from "./index/gallery";
import Services from "./index/services";
import Contact from "./index/contact";

function Index() {
  return (
    <>
      <Hero />
      <About />
     <Portfolio />
     <Gallery />
     <Services />
     <Contact />
      <div className="swiper-container swiper-bg">
        <div className="swiper">
          <div className="swiper-wrapper">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <img src="assets/img/photos/bg4.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/photos/bg2.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/photos/bg3.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/photos/bg4.jpg" alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* <div className="swiper-container swiper-bg" data-effect="fade" data-autoplay="true" data-autoplaytime="7000">
      <div className="swiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img src="assets/img/photos/bg1.jpg" alt="" />
          </div>
          <div className="swiper-slide">
            <img src="assets/img/photos/bg2.jpg" alt="" />
          </div>
          <div className="swiper-slide">
            <img src="assets/img/photos/bg3.jpg" alt="" />
          </div>
          <div className="swiper-slide">
            <img src="assets/img/photos/bg4.jpg" alt="" />
          </div>
        </div>
      </div>
    </div> */}
      {/* <!-- /.swiper-container --> */}
    </>
  );
}

export default Index;
